export const ACCOUNT = 'account';
export const ACCOUNT_AVIA_ORDER = 'account.avia.order';
export const ACCOUNT_HOTEL_ORDER = 'account.hotel.order';
export const ACCOUNT_NAVIGATION = 'account.navigation';
export const ACCOUNT_ORDERS = 'account.orders';
export const ACCOUNT_RAIL_ORDER = 'account.rail.order';
export const ADDITIONAL_PRODUCTS = 'products.additional';
export const ALERTS = 'alers';
export const INSURANCES = 'products.insurances';
export const BODY = 'body';
export const DETAILS = 'details';
export const DIRECTION = 'direction';
export const FLIGHT = 'flight';
export const FOOTER = 'footer';
export const HEADER = 'header';
export const PAGINATION = 'pagination';
export const PASSENGER = 'passenger';
export const PASSENGER_INFO = `${PASSENGER}.info`;
export const FULL_NAME = 'full-name';
export const FIRST_NAME = 'first-name';
export const SURNAME = 'surname';
export const PASSENGERS = 'passengers';
export const PASSENGERS_PAGE = 'passengers-page';
export const DOCUMENTS = 'documents';
export const PAYMENT = 'payment';
export const PROFILE_USER = 'profile-user';
export const PROFILE_TITLE = 'profile-title';
export const PROFILE_USER_HEAD = `${PROFILE_USER}.head`;
export const PROFILE_USER_AVATAR = `${PROFILE_USER}.avatar`;
export const PROFILE_USER_EDIT = `${PROFILE_USER}.edit`;
export const VALUE = 'value';
export const BIRTH_DATE = 'birth-date';
export const DOCUMENT = 'document';
export const DOCUMENT_TYPE = `${DOCUMENT}-type`;
export const DOCUMENT_NUMBER = `${DOCUMENT}-number`;
